.ranking-card{
  border-color: #ede6e6; 
  border-width: 2px;
  border-radius: 8px; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
}

.ranking-badge {
  font-size: 14px;
  font-weight: bold;
  color: black;
  height: 28px;
  width: 28px;
  line-height: 28px;
  border-radius: 50%;
}

.position-rank {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: orange;
  color: black;
  font-weight: bold;
  padding: 10px;
  border-radius: 4px;
  margin-top: 8px;
  width: 100%;
  box-sizing: border-box;
}

.caller-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

.caller-item .ranking-badge {
  margin-right: 5px;
}