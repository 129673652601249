/* .ql-container {
    height: calc(100% - 42px);
}
*/

/* .ql-editor {
    max-height: calc(100vh - 300px);
}  */

.objections__btns {
    display: flex;
    justify-content: flex-end;
    padding: 10px 0;
    gap: 10px;
}

.objection {
    padding: 10px 0;
    border-bottom: solid 1px #e0e0e0;
}

.objection__header {
    display: flex;
    padding-bottom: 10px;
    gap: 10px;
}

.objection__sort {
    flex-basis: 120px;
}

.script-editor .ant-tabs-content-holder {
    height: calc(100vh - 280px);
    width: 100vh;
    overflow-y: scroll;
    padding-right: 5px;
}

.sales-channel {
    display: flex;
    align-items: center;
}

.sales-channel__label {
    margin-right: 15px;
}
